import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import translate from '../i18n/translate';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));

function ProductList(props) {
  //console.group("ProductList");
  const { products } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [checked, setChecked] = React.useState([0]);

  useEffect(() => {
  }, []);

  return (
    <div>
      {props.displayList === false ? ("") : (
        <div>
          <p>{translate('PRODUCT_FOUND')}: {props.totalProductCount}</p>

          {products.length ?
          <Grid container className="tableProductsHeader" alignItems="center">
            <Grid item xs={12} sm={3} md={3} className={classes.image} justify="center" >
              <strong>{translate('PRODUCT_SKU')}</strong>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              <strong>{translate('PRODUCT_DESCRIPTION')}</strong>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              <strong>{translate('PRODUCT_BRAND')}</strong>
            </Grid>
          </Grid>
          : ""}

          <List>
            {products.map((product) => (
              <ListItem
                button
                onClick={() => props.onClick(product)}
                key={product.sku}
              >
                <Divider />
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={3} md={3} className={classes.image}>
                    <b>{translate('PRODUCT_SKU')}:</b> {product.sku}
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} className={classes.image}>
                    <b>{translate('PRODUCT_DESCRIPTION')}:</b> {product.productDescription1} {product.productDescription2}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className={classes.image}>
                    <b>{translate('PRODUCT_BRAND')}:</b> {product.brandFullDescription}
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.image}>
                    <div className="fakeButton">{translate('SELECT')}</div>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}


export default ProductList;