import React, { useEffect, useState } from "react";

import { makeStyles /*, useTheme*/ } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import Resizer from "react-image-file-resizer";
import "react-responsive-modal/styles.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: "2147483632 !important",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  input: {
    display: "none",
  },
  label: {
    margin: "15px",
  },
}));

UploadPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function UploadPopUp() {
  const [message, setMessage] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [hideChooseImage, setHideChooseImage] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [file, setFile] = useState("");
  const [titleText, setTitleText] = useState("");
  const [subtitleText, setSubTitleText] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("");
  const [chooseButtonText, setChooseButtonText] = useState("");
  const classes = useStyles();

  const RESIZER_PROGRESS = 50;
  const MAX_FILE_SIZE = 45;
  const FILE_SIZE_LIMIT = MAX_FILE_SIZE * 1024 * 1024;
  const ACCEPTED_IMAGE_FILE_TYPE = ["image/jpg", "image/jpeg", "image/png"];

  const intl = useIntl();

  const resizeFile = (options) =>
    new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          options.file,
          options.maxWidth,
          options.maxHeight,
          options.compressFormat,
          options.quality,
          options.rotation,
          (uri) => {
            resolve(uri);
          },
          options.outputType
        );
      } catch (error) {
        console.log(error.message);
        reject(error);
      }
    });

  function isAnImageType(fileType) {
    if (ACCEPTED_IMAGE_FILE_TYPE.includes(fileType)) {
      return true;
    }

    return false;
  }

  async function sendFile(e) {
    try {
      e.preventDefault();
      console.log(`file type: ${file.type}`);

      if (file.size > FILE_SIZE_LIMIT) {
        cleanDialog();
        setMessage({
          kind: "error",
          body: `FILE SIZE TOO LARGE (max ${MAX_FILE_SIZE} Mb).`,
        });
        return;
      }

      let fileToSend = file;

      if (isAnImageType(file.type)) {
        console.log(`Starting resizer...`);
        console.log(`Old image size: ${file.size} byte.`);

        const imageResizerOpt = {
          file: file, // Is the file of the image which will resized.
          maxWidth: 2000, // Is the maxWidth of the resized new image.
          maxHeight: 2000, // Is the maxHeight of the resized new image.
          compressFormat: "JPEG", // Is the compressFormat of the resized new image.
          quality: 90, // Is the quality of the resized new image.
          rotation: 0, // Is the degree of clockwise rotation to apply to uploaded image.
          outputType: "file", // Is the output type of the resized new image.
        };

        const resizedImage = await resizeFile(imageResizerOpt);
        console.log(resizedImage);
        console.log(`New image size: ${resizedImage.size} byte.`);

        setProgress(RESIZER_PROGRESS);

        fileToSend = resizedImage;
      }

      // if (!isFileAccepted(file.type)) {
      //   cleanDialog();
      //   setMessage({
      //     kind: "error",
      //     body: `IMAGE FORMAT NOT ACCEPTED (only: ${printTypes(
      //       ACCEPTED_IMAGE_FILE_TYPE
      //     )}).`,
      //   });
      //   return;
      // }

      const formdata = new FormData();
      formdata.append("file", fileToSend);

      const axiosReqOpt = {
        method: "post",
        url: `https://hive.${window.heresAgent}.heresbot.it/api/Files/uploadV2`,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const convertedProgress = Math.floor(
            (progressEvent.loaded / fileToSend.size) * (100 - RESIZER_PROGRESS)
          );

          console.log(`postProgress: ${convertedProgress}`);
          setProgress(RESIZER_PROGRESS + convertedProgress);
        },
      };

      // throw new Error("Test");
      const response = await axios(axiosReqOpt);
      window.sendMessage(response.data.url);
      setOpen(false);
      setHideChooseImage(true);
      setProgress(0);
      setMessage(null);
    } catch (error) {
      console.error(ts(), "fetch rows req error", e);
      setMessage({ kind: "error", body: "INTERNAL ERROR" });
    }
  }

  function ts() {
    return "uploadImage) " + new Date().toJSON();
  }

  async function selectImage(e) {
    cleanDialog();
    setFile(e);
    setHideChooseImage(false);
    setProgress(0);
  }

  const handleClose = () => {
    // close dialog
    setOpen(false);

    if ("sendMessage" in window) {
      window.sendMessage("CANCELLATO", intl.formatMessage({ id: "CANCELLED" }));
    }

    cleanDialog();
  };

  function cleanDialog() {
    // setup image selected to false
    setHideChooseImage(true);
    // progress-bar to 0
    setProgress(0);
    // error aler cleaned
    setMessage(null);
  }

  useEffect(() => {
    window.addEventListener("uploadImage", (event) => {
      console.log(event);
      setTitleText((event.data && event.data.title) ?? "");
      setSubTitleText((event.data && event.data.subtitle) ?? "");
      setChooseButtonText(
        (event.data && event.data.chooseButton) ?? "Choose Image"
      );
      setUploadButtonText(
        (event.data && event.data.uploadButton) ?? "Upload Image"
      );
      setOpen(true);
      setMessage(null);
    });
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xl"
      modal={true}
      classes={{ root: classes.root }}
    >
      <DialogTitle id="simple-dialog-title">
        <button
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </button>
      </DialogTitle>
      <DialogContent>
        <div id="upload_content">
          <h1>{titleText}</h1>
          <h2>{subtitleText}</h2>
          {message && (
            <Box mt={2} mb={2}>
              <Alert severity={message.kind}>
                {message.body}{" "}
                {message.spinner && <CircularProgress size="10px" />}
              </Alert>
            </Box>
          )}
          <Box id="upload-image" hidden={!hideChooseImage}>
            <div className={classes.root}>
              <input
                className={classes.input}
                id="contained-button-file"
                onChange={(e) => selectImage(e.target.files[0])}
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  id="choose-file-button"
                  component="span"
                >
                  {chooseButtonText}
                </Button>
              </label>
            </div>
          </Box>
          <Box hidden={hideChooseImage}>
            <label id="upload_image_name_label">{file.name}</label>
            <Button
              variant="contained"
              id="choose-file-button"
              component="span"
              type="submit"
              onClick={sendFile}
            >
              {uploadButtonText}
            </Button>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
}
