import React, { /*useEffect,*/ useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { useAuth } from "../context/auth";
import translate from '../i18n/translate';
import Footer from "../components/Footer";

function Login() {
  const [/*isLoggedIn*/, setLoggedIn] = useState(false);
  //const [language, setLanguage] = useState("en");
  //const [isLoaded, setIsLoaded] = useState(false);
  const [/*isError*/, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();
  const [errors, setErrors] = useState([]);

  /*
  Questo era usato nella vecchia implementazione multi dominio,
  nella nuova implementazione a dominio unico la lingua
  pre-autenticazione è sempre e solo inglese
  useEffect(() => {
    axios.get("/api/v1/config")
      .then(
        async (result) => {
          if (result.status === 200) {
            setLanguage(result.data.data.language);
            setIsError(result.data.error);

            console.error(result.data.error);
          }
        },
        (error) => {
          setIsLoaded(true);
          setIsError(error.message);
        }
      )
  }, []);
  */

  async function handleSubmit(event) {
    event.preventDefault();
    axios.post("/api/v1/auth/login", {
      email: event.target.email.value,
      password: event.target.password.value
    }).then(result => {
      if (result.status === 200) {
        setAuthTokens(result.data.data.token);
        setLoggedIn(result.data.login);
        window.location.href = '/me';
      } else {
        setIsError(result.data.error);
      }
    }).catch(e => {
      //setIsError(true);
      if (e.response) {
        setIsError(e.response.data.error); // => the response payload 
        setErrors(e.response.data.error.details.body);
      }
    });
  }

  return (
    <div id={"main-container"}>
      <Grid container component="main" id={"container"}>
        <Grid item sm={0} md={2} />

        <Grid item sm={12} md={4} className={"login-container"}>
          <div className={"login-content"}>
            <img src={"/img/asdp-logo.svg"} alt="ASDP - After Sales Digital Platform" className={"login-logo"} />
            <h2 className={"login-title"} style={{ display: "none" }}>
              {/*translate('WELCOME_MESSAGE')*/}
            </h2>
            <p className={"login-text"} style={{ display: "none" }}>
              {/*translate('WELCOME_VIRTUAL_ASSISTANCE')*/}&nbsp;
              {/*translate('WELCOME_GUIDE')*/}
            </p>

            <form noValidate onSubmit={handleSubmit} className={"login-form"}>
              <TextField
                variant="outlined"
                size="small"
                className={"input-item"}
                required
                fullWidth
                id="email"
                label={translate('EMAIL_ADDRESS')}
                name="email"
                autoComplete="email"
                autoFocus
                value={userName}
                onChange={e => {
                  setUserName(e.target.value);
                }}
              />
              <TextField
                variant="outlined"
                size="small"
                className={"input-item"}
                required
                fullWidth
                name="password"
                label={translate('PASSWORD')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
              {
                errors.length > 0 &&
                <Box mb={3}>
                  {errors.map(err => (<Alert severity="error">{err.context.label}: {translate(err.message)}</Alert>))}
                </Box>
              }
              <div className={"forgot-pw-link"}>
                <Link href="/reset-password-request" variant="body2">
                  {translate('FORGOT_PASSWORD')}?
                </Link>
              </div>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className={"button-item"}
                color="primary"
                size="large"
              >
                {translate('SIGN_IN')}
              </Button>
            </form>
          </div>
        </Grid>

        <Grid item sm={0} md={6} />
      </Grid>
      <Footer />
    </div>
  );
}

export default Login;