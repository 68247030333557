import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import axios from "axios";
import translate from "../i18n/translate";
import InvoiceSearch from "../components/InvoiceSearch";
import ProductSearch from "../components/ProductSearch";
import UploadPopUp from "../components/UploadPopUp";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocaleContext } from "../context/locale";

function Me() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alphaName, setAlphaName] = useState([]);
  const { setLanguage } = useLocaleContext();

  useEffect(() => {
    window.onbeforeunload = confirmExit;

    function confirmExit() {
      localStorage.clear();
      return true;
    }

    axios
      .get("/api/v1/me")
      .then(
        function (result) {
          if (result.status === 200) {
            setIsLoaded(true);
            setAlphaName(result.data.data.alphaName);
            setLanguage(result.data.data.language);
          } else {
            console.error(
              "(request-error) " + result.status,
              result && result.data
            );
            setError("(request-error) " + result.data && result.data.error);
          }
        },
        function (error) {
          console.error("(request-fail) ", error);
          setIsLoaded(true);
          setError("(request-fail)");
        }
      )
      .catch((err) => {
        console.error("internal-error:", err);
        setError("(internal-error)");
      });
  });

  if (error) {
    return (
      <div id={"alert-container"}>
        <Alert severity="error">
          {translate("ERROR")}: {error}
        </Alert>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div id={"alert-container"}>
        <Alert icon={<HourglassEmptyIcon fontSize="inherit" />} severity="info">
          {translate("LOADING")}...
        </Alert>
      </div>
    );
  } else {
    return (
      <div id={"main-container"}>
        <Navbar alphaName={alphaName} />
        <Grid container component="main" id={"container"}>
          <Grid item sm={12} md={12}>
            <Helmet>
              <title>Chatbot</title>
              <script src="/chatbot.js" />
            </Helmet>
          </Grid>
          <InvoiceSearch />
          <ProductSearch />
          <UploadPopUp />
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default Me;
