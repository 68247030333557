import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import translate from '../i18n/translate';
import { blue } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import _ from 'lodash';


const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));


function ProductItemList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [checked, setChecked] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
  }, []);

  /*const handleToggle = async (e) => {
    let invoiceProductRowId = e.target.value;
    let products = props.invoiceProducts;

    let selectedProduct = await _.find(products, function (o) {
      return o.id === parseInt(invoiceProductRowId);
    });

    if (!('quantityReturned' in selectedProduct)) {
      selectedProduct['quantityReturned'] = 0;
    }

    let selectedIndex = selectedProducts.indexOf(selectedProduct);


    if (selectedIndex === -1) {
      if (!checked.includes(parseInt(invoiceProductRowId))) {
        checked.push(parseInt(invoiceProductRowId));
      }
      selectedProducts.push(selectedProduct);
    } else {
      selectedProducts.splice(selectedIndex, 1);
      let checkedIndex = checked.indexOf(invoiceProductRowId);
      checked.splice(checkedIndex, 1);
    }
    setSelectedProducts(selectedProducts)
    setChecked(checked);
  };*/

  const handleQuantityChange = async (e) => {
    try {

      let quantityString = e.target.value;
      let products = props.invoiceProducts;

      let quantityObject = quantityString.split('-');
      let invoiceProductRowId = quantityObject[0];
      let returnedProductQuantity = parseInt(quantityObject[1], 10);

      let selectedProduct = await _.find(selectedProducts, function (o) {
        return o.id === invoiceProductRowId;
      });

      if (selectedProduct) {

        if ('quantityReturned' in selectedProduct) {
          selectedProduct['quantityReturned'] = returnedProductQuantity;
        } else {
          selectedProduct['quantityReturned'] = returnedProductQuantity;
        }


        let selectedIndex = selectedProducts.indexOf(selectedProduct);

        if (selectedIndex === -1) {
          selectedProducts.push(selectedProduct);
          if (!checked.includes(invoiceProductRowId)) {
            checked.push(invoiceProductRowId);
          }
        } else {
          if (returnedProductQuantity === 0) {
            selectedProducts.splice(selectedIndex, 1);
            let checkedIndex = checked.indexOf(invoiceProductRowId);
            checked.splice(checkedIndex, 1);
          }
        }
      } else {
        selectedProduct = await _.find(products, function (o) {
          return o.id === invoiceProductRowId;
        });

        let selectedIndex = selectedProducts.indexOf(selectedProduct);

        if (selectedIndex === -1) {
          if ('quantityReturned' in selectedProduct) {
            selectedProduct['quantityReturned'] = returnedProductQuantity;
          } else {
            selectedProduct['quantityReturned'] = returnedProductQuantity;
          }
          selectedProducts.push(selectedProduct);
          checked.push(invoiceProductRowId);
        } else {
          if (returnedProductQuantity === 0) {
            selectedProducts.splice(selectedIndex, 1);
            let checkedIndex = checked.indexOf(invoiceProductRowId);
            checked.splice(checkedIndex, 1);
          }
        }
      }
      setSelectedProducts(selectedProducts);
      setChecked(checked);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    props.handleClose();
  }

  const handleSave = () => {
    props.onSaveAction(selectedProducts);
    setSelectedProducts([]);
    setChecked([]);
  }
  /*
  const isChecked = async (invoiceProductRowId) => {
    console.log(`isChecked => ${invoiceProductRowId} - ${JSON.stringify(checked)}`);
    let isCheckedValue = checked.includes(invoiceProductRowId);
    console.log(`isChecked => ${invoiceProductRowId} - ${JSON.stringify(isCheckedValue)} - ${JSON.stringify(checked)}`);
    return parseBool(isCheckedValue);
  }
  */

  return (
    <div>
      {props.displayList === false ? ("") : (
        <div>
          <h1>{props.title}</h1>
          <p><strong>{translate('INVOICE_NUMBER')}:</strong> {props.invoiceNumber} </p>
          <p><strong>{translate('INVOICE_DATE')}:</strong> {props.invoiceDate} </p>
          <button onClick={() => {
            setSelectedProducts([]);
            props.backToInvoice();
          }}>{translate('BACK_TO_INVOICE')}</button>

          <Grid container className="tableInvoiceHeader" alignItems="center">
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              {translate('INVOICE_ITEM')}
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              {translate('INVOICE_DESCRIPTION')}
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              {translate('INVOICE_QUANTITY_PURCHASED')}
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              {translate('INVOICE_QUANTITY')}
            </Grid>
          </Grid>

          <List>
            {props.invoiceProducts.map((invoiceProduct, index) => (
              <ListItem
                autoFocus
                key={invoiceProduct.id}
              >
                <ListItemIcon>
                  {/*
                      <Checkbox
                      onChange={ (e) => handleToggle(e) }
                      checked={checked.includes(parseInt(invoiceProduct.id), 0)}
                      edge="start"
                      value={invoiceProduct.id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 1 }}
                    />
                    */}

                </ListItemIcon>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={8} md={4} className={classes.image}>
                    <b>{translate('INVOICE_ITEM')}:</b> {invoiceProduct.sku}
                  </Grid>
                  <Grid item xs={12} sm={8} md={4} className={classes.image}>
                    <b>{translate('INVOICE_DESCRIPTION')}:</b> {invoiceProduct.productDescription1} {invoiceProduct.productDescription2}
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} className={classes.image}>
                    <b>{translate('INVOICE_QUANTITY_PURCHASED')}:</b> {invoiceProduct.quantity}
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} className={classes.image}>
                    <b>{translate('INVOICE_QUANTITY')}:</b>
                    <Select
                      native
                      onChange={(e) => handleQuantityChange(e)}
                      inputProps={{
                        name: 'refundedQuantity',
                        id: 'returner-product-quantity',
                      }}
                    >
                      <option aria-label="None" value={`${invoiceProduct.id}-0`}>0</option>
                      {Array.from(Array(parseInt(invoiceProduct.quantity, 10)), (e, i) => {
                        return <option value={`${invoiceProduct.id}-${i + 1}`}>{i + 1}</option>
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          {props.message && <Box mt={2}>
            <Alert severity={props.message.kind}>{props.message.body} {props.message.spinner && <CircularProgress size="10px"/>}</Alert>
          </Box>}
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
            >
              {translate('CANCEL')}
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
            >
              {translate('SAVE')}
            </Button>
          </DialogActions>
        </div>
      )}
    </div>
  );
}


export default ProductItemList;