import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import HomeRoute from './HomeRoute';
import Me from './pages/Me';
import RequestResetPassword from './pages/RequestResetPassword';
import Login from "./pages/Login";
import { AuthContext } from "./context/auth";
import { LocaleContext } from "./context/locale";
import { I18nProvider } from './i18n'
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import OnlyOneTabComponentCustom from './components/OnlyOneTabComponentCustom';
//import { createTheme } from '@material-ui/core/styles';

/*const theme = createTheme({
  palette: {
    primary: {
      light: '#b73ccd',
      main: '#b73ccd',
      dark: '#b73ccd',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});*/

function App(props) {
  const existingTokens = localStorage.getItem("token");
  let [language, setLanguage] = useState("en");
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("token", JSON.stringify(data));
    setAuthTokens(data);
  }

  return (
    <LocaleContext.Provider value={{language, setLanguage}}>
      <I18nProvider language={language}>
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
          <Router>
            <Switch>
              <Route path="/reset-password-request" component={RequestResetPassword} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/me" component={Me} />
              <HomeRoute exact path="/" component={Login} />
            </Switch>
          </Router>
        </AuthContext.Provider>
      </I18nProvider>
    </LocaleContext.Provider>
  );
}

export default withOneTabEnforcer({
  appName: "shimano-plugin-logistica",
  localStorageTimeout: 15 * 1000,
  localStorageResetInterval: 10 * 1000,
  OnlyOneTabComponent: OnlyOneTabComponentCustom
})(App);