import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import translate from '../i18n/translate';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));


export default function InvoiceSearchForm(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  return (
    <div>
      {props.display === false ? ("") : (
        <div>
          <h1>{props.title}</h1>
          <h2 className="disclaimer">{props.subtitle}</h2>
          <form
            className={classes.form}
            noValidate
            onSubmit={props.onSubmit}
            onKeyDown={props.onKeyDown}
            onChange={props.invoiceSearchQuery}
          >
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={12} sm={8} md={8} className={classes.image}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="search-invoice"
                  label={translate('SEARCH_INVOICE')}
                  name="search"
                  autoComplete="search"
                  autoFocus
                  value={props.invoiceSearchValue}
                  onChange={(e) => props.onSearchChange(e)}
                />
              </Grid>
              {
                /*
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >{translate('SEARCH')}
                </Button>
                */
              }
              <Grid item xs={12} sm={4} md={4} className={classes.image}>

              </Grid>
            </Grid>
            {errors.length > 0 && <Box mt={2}>
              {errors.map((err) => (
                <Alert severity="error">{err.context.label} {translate(err.message)}</Alert>
              ))
              }
            </Box>
            }
          </form>
        </div>
      )}
    </div>
  );
}
    