import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import translate from "../i18n/translate";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: '#fff',
    height: '80px',
    padding: '0 100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#009ad9'
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    backgroundColor: 'rgba(0, 154, 217, 0.1)',
    color: '#009ad9',
    border: 'none',
    borderRadius: '35px',
    marginLeft: '2rem',
    '&:hover': {
      backgroundColor: '#009ad9',
      color: '#fff'
    }
  }
}));

function logOut() {
  localStorage.clear();
  //setAuthTokens('');
  window.location.href = '/login';
}

export default function Navbar(props) {
  const classes = useStyles();

  return (
    <div id={"navbar"} className={classes.navbar}>
      <img src={"/img/asdp-logo-or.svg"} alt="ASDP - After Sales Digital Platform" />
      <div className={classes.rightSide}>
        <span>{translate('HI')}, {props.alphaName}</span>
        <Button
          onClick={logOut}
          variant="contained"
          color="primary"
          className={classes.button}
          disableElevation
        >
          {translate('LOG_OUT')}
        </Button>
      </div>
    </div>
  );
}
