import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import { makeStyles /*, useTheme*/ } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { blue } from '@material-ui/core/colors';
import { DialogContent } from '@material-ui/core';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProductList from './ProductList';
import ProductSearchForm from './ProductSearchForm';
import translate from '../i18n/translate';
import { useIntl } from 'react-intl';


const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));

const reqcache = {}
const lock = {on: false, queue: []}
const CACHE_EXPIRATION = 60000
const MIN_CHAR = 5
let delayedsearch = null
let searchkind = 'sku'
let lastsearchval = ''

function ProductSearchPopUp(props) {
  //console.group("ProductSearchPopUp"); 
  const classes = useStyles();
  //const theme = useTheme();
  const [message, setMessage] = useState([]);
  const [productSearchValue, setProductSearchValue] = useState("");
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [/*invoiceProducts*/, setInvoiceProducts] = useState([]);
  const [displayInvoiceList, setDiplayInvoiceList] = useState(true);
  const [displayProductSearchForm, setDiplayProductSearchForm] = useState(false);
  const intl = useIntl()

  // const [invoiceNumberSelected, setInvoiceNumberSelected] = useState(null);
  // const [invoiceDateSelected, setInvoiceDateSelected] = useState(null);

  //const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const {
    onClose,
    selectedValue,
    open,
    firstCopyTab,
    //productPresent,
    //dateStart,
    //dateEnd,
    //copySecondTab
  } = props;

  function getCache() {
    const now = Date.now()

    if (!reqcache.expiration || now > reqcache.expiration) {
      reqcache.q = {}
      reqcache.expiration = now + CACHE_EXPIRATION
    }

    return reqcache.q
  }

  async function lockAcquire() {
    if (!lock.on) {
        lock.on = true
        return true
    }

    return new Promise((resolve, reject) => {
        lock.queue.push({resolve, reject})
    })
  }

  function lockRelease() {
    const last = lock.queue.pop()

    for (let i = 0; i < lock.queue.length; i++) {
      /// cancel other pending request
      lock.queue[i].resolve(false)
    }

    if (last)
      last.resolve(true)

    lock.on = false
  }

  function ts() {
    return "prodsearch) " + (new Date()).toJSON()
  }

  async function getProducts(q, mode) {
    const cache = getCache()
    const query = `q=${encodeURIComponent(q)}&mode=${mode}`

    if (cache[query]) {
      console.log(ts(), "[cached] fetch product", query)
      return cache[query]
    }

    /*const locked = await lockAcquire()

    if (!locked) {
      console.log(ts(), "[cached] fetch product", query, "... aborted")
      return null
    }*/

    console.log(ts(), "[send-req] fetch product", query)

    let result = await axios.get(`/api/v1/products/?${query}`)

    if (result.status === 200) {
      cache[query] = result
    }

    //lockRelease()

    return result
  }

  function lazyLoadProducts(q, mode) {
    clearTimeout(delayedsearch)
    delayedsearch = setTimeout(function() {
      loadProducts(q, mode)
    }, 1000)
  }

  async function loadProducts(q, mode) {
    try {
      q = q.trim()
      console.log(ts(), "load product", q, "...")
      if (q.length < MIN_CHAR) {
        console.log(ts(), "load product", q, "... ignored (too few chars)")
        setMessage({
          kind: 'warning',
          body: translate('AT_LEAST_N_CHAR', {nchar: MIN_CHAR})
        })
        return
      }

      console.log(ts(), "load product", q, " -> fetch")

      setMessage({kind: 'info', body: `loading ...`, spinner: true})
      setInvoiceProducts([]);
      setProducts([]);
      setTotalProductCount('-');

      let response = await getProducts(q, mode)

      if (!response) {
        console.log(ts(), "cancel concurrent request", q)
        return
      }

      if (response.status === 200) {
          const tot = response.data.data.total
          setDiplayProductSearchForm(true);
          setTotalProductCount((tot >= 50) ? '> 50' : tot);
          setDiplayInvoiceList(true);
          setInvoiceProducts([]);
          setProducts(response.data.data.data);
          setMessage(null)
      } else {
          console.error(ts(), "fetch product req status=", response.status, "body=", response.data.error)
          setMessage({kind: 'error', body: 'API ERROR ' + response.data.error})
      }
    } catch (e) {
        console.error(ts(),  "fetch product req error", e)
        setMessage({kind: 'error', body: 'API ERROR'})
    }
  }

  useEffect(() => {
    async function fetchExamples() {
      /// WORKAROUND nessuna API che mostri un sotto-insieme di elementi
      await loadProducts('ABR4700AR87A', 'sku')
    }
    fetchExamples()
  }, []);

  const handleClose = () => {
    setDiplayProductSearchForm(true)
    setDiplayInvoiceList(true)
    setInvoiceProducts([])

      /// cancelliamo eventuali messaggi di errori api
      setMessage(null)

      // resettiamo la ricerca e ricarichiamo le invoices
      // nel caso la finestra venga riaperta in seguito
      //lastsearchval = ''
      //setProductSearchValue('')

    if ('sendMessage' in window) {
      window.sendMessage("CANCELLATO", intl.formatMessage({id: "CANCELLED"}));
    }
    onClose(selectedValue);
  };

  const handleProductItemClick = async (value) => {
    try {
      let separator = '__!__';
      window.sendMessage(`${value.sku}${separator}${value.productDescription1}${value.productDescription2}${separator}${value.botFlow}`, `${value.sku}`);
      onClose(`${value.number}__!__${value.date}`);
    } catch (e) {
      console.error(ts(),  "itemclick error", e);
    }
  };

  function handleSubmit(event) {
    event.preventDefault()
    console.log(ts(), "prevent submit")
  }

  function handleKeyDown(event) {
    const key = event && event.key
    if (key === 'Enter') {
      event.preventDefault()
      console.log(ts(), "prevent submit (by keydown)")
    }
  }

  async function setProductSearchQuery(value) {
    lazyLoadProducts(value, searchkind)
    lastsearchval = value
  }

  const onSearchChange = async (e) => {
    const val = e.target.value
    setProductSearchValue(val)
    setProductSearchQuery(val)
  }

  const onSearchKindChange = (e) => {
    searchkind = e.target.value
    setProductSearchValue(lastsearchval);
    setProductSearchQuery(lastsearchval);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xl"
      modal={true}
      classes={{ root: classes.root }}
    >
      <DialogTitle id="simple-dialog-title">
        <button onClick={() => {
          handleClose()
        }}>Close
        </button>
      </DialogTitle>
      <DialogContent>
        <Box id="product-search">
          <ProductSearchForm
            display={displayProductSearchForm}
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            onSearchChange={onSearchChange}
            onSearchKindChange={onSearchKindChange}
            title={firstCopyTab}
            message={message}
          />
        </Box>
        <Box id="product-list">
          <ProductList
            totalProductCount={totalProductCount}
            products={products}
            onClick={handleProductItemClick}
            displayList={displayInvoiceList}
            productSearchValue={productSearchValue}
          />
        </Box>
      </DialogContent>

    </Dialog>
  );
}

ProductSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ProductSearch() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [firstCopyTab, setFirstCopyTab] = React.useState("");

  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    window.addEventListener('searchProduct', (event) => {
      setFirstCopyTab(event.data.copyFirstTab);
      setOpen(true);
    });
  }, []);

  return (
    <div>
      <ProductSearchPopUp
        firstCopyTab={firstCopyTab}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
