import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import translate from '../i18n/translate';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));

function InvoiceList(props) {
  //console.group("InvoiceList");
  const { invoices } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [checked, setChecked] = React.useState([0]);

  useEffect(() => {
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div>
      {props.displayList === false ? ("") : (
        <div>
          <p>{translate('INVOICE_FOUND')}: {props.totalInvoiceCount}</p>
          {(invoices && invoices.length) ?
          <Grid container className="tableInvoicesHeader" alignItems="center">
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              <strong>{translate('INVOICE_NUMBER')}</strong>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
              <strong>{translate('INVOICE_DATE')}</strong>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.image} justify="center" >
            </Grid>
          </Grid>
          : ""}

          <List>
            {invoices && invoices.map((invoice) => (
              <ListItem
                button
                onClick={() => props.onClick(invoice)}
                key={invoice.number}
              >
                <Divider />
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <Grid container alignItems="center">
                  <Grid item xs={8} sm={4} md={6} className={classes.image}>
                    <b>{translate('INVOICE_NUMBER')}:</b> {invoice.number}
                  </Grid>
                  <Grid item xs={8} sm={4} md={5} className={classes.image}>
                    <b>{translate('INVOICE_DATE')}:</b> {invoice.date}
                  </Grid>
                  <Grid item xs={4} sm={4} md={1} className={classes.image}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >{translate('SELECT')}
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}


export default InvoiceList;