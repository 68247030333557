import translate from '../i18n/translate';
import { I18nProvider, LANGUAGES } from '../i18n'
import Alert from "@material-ui/lab/Alert";

const DifferentWarningComponent = () => {
  return (
    <I18nProvider language={LANGUAGES.ITALIAN}>
      <div id={"alert-container"}>
        <Alert severity="warning" className={"top-page-alert"}>{translate('ONE_TAB_WARNING_MESSAGE')}</Alert>
      </div>
    </I18nProvider>
  );
}

export default DifferentWarningComponent;