import en from './en';
import it from './it';
import es from './es';
import pt from './pt';

const LANGUAGES = {
  ...en,
  ...it,
  ...es,
  ...pt
}

export default LANGUAGES