import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import translate from '../i18n/translate';
import Footer from "../components/Footer";

function RequestResetPassword() {
  const [error, setError] = useState(false);
  //const [language, setLanguage] = useState("EN");
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [errors, setErrors] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
      /*
      Questo era usato nella vecchia implementazione multi dominio,
      nella nuova implementazione a dominio unico la lingua
      pre-autenticazione è sempre e solo inglese
    try {
      axios.get("/api/v1/config")
        .then(
          async (result) => {
            if (result.status === 200) {
              setLanguage(result.data.data.language);
              setIsError(result.data.error);
              setIsLoaded(true);
              console.error(result.data.error);
            } else {
              console.error(result.data.error);
              setIsLoaded(true);
            }
          },
          (error) => {
            setIsLoaded(true);
            setIsError(error);
          }
        )
    } catch (e) {
      console.error(e.message);
    }
    */
    setIsLoaded(true)
  }, []);


  async function handleSubmit(event) {
    event.preventDefault();
    axios.post("/api/v1/auth/reset-password-request", {
      email: event.target.email.value,
    }).then(result => {
      if (result.status === 200) {
        setMessages([{ message: result.data.data.message }]);
      } else {
        console.error(result.data);

        setIsError(result.data.error);
      }
    }).catch(e => {
      //setIsError(true);
      if (e.response) {
        setIsError(e.response.data.error); // => the response payload 
        setErrors(e.response.data.error.details.body);
      }
    });
  }

  if (error) {
    return (
      <div id={"alert-container"}>
        <Alert severity="error">{translate('ERROR')}: {error.message}</Alert>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div id={"alert-container"}>
        <Alert icon={<HourglassEmptyIcon fontSize="inherit" />} severity="info">{translate('LOADING')}...</Alert>
      </div>
    );
  } else {
    return (
      <div id={"main-container"}>
        <Grid container component="main" id={"container"}>
          <Grid item sm={0} md={2} />

          <Grid item sm={12} md={4} className={"login-container"}>
            <div className={"login-content"}>
              <img src={"/img/asdp-logo.svg"} alt="ASDP - After Sales Digital Platform" className={"login-logo"} />
              <h2 className={"login-title"}>{translate('RESET_PASSWORD_REQUEST')}</h2>

              <form noValidate onSubmit={handleSubmit} className={"login-form"}>
                <TextField
                  variant="outlined"
                  size="small"
                  className={"input-item"}
                  required
                  fullWidth
                  label={translate('EMAIL_ADDRESS')}
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={userName}
                  onChange={e => {
                    setUserName(e.target.value);
                  }}
                />
                {
                  messages.length > 0 &&
                  <Box mt={3}>{
                    messages.map(message => (
                      <Alert severity="success" key={message.message}>{translate(message.message)}</Alert>
                    ))
                  }</Box>
                }
                {
                  errors.length > 0 &&
                  <Box mt={3}>
                    {errors.map(err => (<Alert severity="error">{err.context.label}: {translate(err.message)}</Alert>))}
                  </Box>
                }
                <div className={"forgot-pw-link"}>
                  <Link href="/" variant="body2">
                    {translate('BACK_TO_LOGIN')}?
                  </Link>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  className={"button-item"}
                  color="primary"
                  size="large"
                >
                  {translate('SEND')}
                </Button>
              </form>
            </div>
          </Grid>

          <Grid item sm={0} md={6} />
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default RequestResetPassword;