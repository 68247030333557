import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#000',
    height: '80px',
    padding: '0 100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  copyright: {
    color: 'rgba(255, 255, 255, 0.7)'
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div id={"footer"} className={classes.footer}>
      <img src={"/img/shimano-logo.svg"} alt="ASDP - After Sales Digital Platform" />
      {/*<span className={classes.copyright}>&copy; SHIMANO INC. ALL RIGHTS RESERVED</span>*/}
    </div>
  );
}
