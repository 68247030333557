import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import { makeStyles /*, useTheme*/ } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { blue } from '@material-ui/core/colors';
import { DialogContent } from '@material-ui/core';
import ProductItemList from './ProductItemList';
import InvoiceList from './InvoiceList';
import InvoiceSearchForm from './InvoiceSearchForm';
import translate from '../i18n/translate';
import { useIntl } from 'react-intl';


const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));

const reqcache = {}
const CACHE_EXPIRATION = 60000
const MIN_CHAR = 8
let delayedsearch = null
let lastsearch = ''

export default function InvoiceSearchPopUp(props) {
  const classes = useStyles();
  const [message, setMessage] = useState([]);
  const [invoiceSearchValue, setInvoiceSearchValue] = useState("");
  const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [displayInvoiceList, setDiplayInvoiceList] = useState(true);
  const [displayInvoiceProductList, setDiplayInvoiceProductList] = useState(false);
  const [displayInvoiceSearchForm, setDiplayInvoiceSearchForm] = useState(false);
  const [invoiceNumberSelected, setInvoiceNumberSelected] = useState(null);
  const [invoiceDateSelected, setInvoiceDateSelected] = useState(null);
  const [messageInvoiceProductList, setMessageDiplayInvoiceProductList] = useState(null);
  const intl = useIntl()

  const {
    onClose,
    selectedValue,
    open,
    firstCopyTab,
    copyDisclaimer,
    productPresent,
    dateStart,
    //dateEnd,
    copySecondTab
  } = props;

  function ts() {
    return "invsearch) " + (new Date()).toJSON()
  }

  function getCache() {
    const now = Date.now()

    if (!reqcache.expiration || now > reqcache.expiration) {
      reqcache.q = {}
      reqcache.expiration = now + CACHE_EXPIRATION
    }

    return reqcache.q
  }

  async function fetchInvoice(query) {
    const cache = getCache()

    if (cache[query]) {
      console.log(ts(), "[cached] fetch invoice", query)
      return cache[query]
    }

    console.log(ts(), "[send-req] fetch invoice", query)

    let result = await axios.get(`/api/v1/invoices/${query}`)

    if (result.status === 200) {
      cache[query] = result
    }

    return result
  }

  async function getInvoices(num) {
    if (!num) {
      /* ritorna tutte le fatture */
      return await fetchInvoice('')
    } else {
      /* cerca una fattura specifica */
      return await fetchInvoice(`?q=${encodeURIComponent(num)}`)
    }
  }

  async function getInvoiceRows(num) {
    if (!num)
      throw new Error(`necessario numero fattura`)

    return await fetchInvoice(encodeURIComponent(num))
  }

  function resetForm(showrows) {
    setDiplayInvoiceSearchForm(!showrows)
    setDiplayInvoiceList(!showrows)
    setDiplayInvoiceProductList(showrows)
    setMessage({kind: 'info', body: `loading ...`, spinner: true})

    if (showrows) {
      setInvoiceProducts([])
    } else {
      setInvoices([])
      setTotalInvoiceCount('-')
    }
  }

  function lazyLoadInvoices(q) {
    clearTimeout(delayedsearch)
    delayedsearch = setTimeout(function() {
      loadInvoices(q)
    }, 1000)
  }

  async function loadInvoices(num) {
    try {
      if (num) {
        num = num.trim()
        console.log(ts(), "load invoice", num, "...")
        if (num.length < MIN_CHAR) {
          setMessage({kind: 'warning', body: translate('AT_LEAST_N_CHAR', {nchar: MIN_CHAR})})
          return
        }
      } else {
        console.log(ts(), "load all invoice...")
      }

      resetForm(false)

      let response = await getInvoices(num)

      if (response.status === 200) {
        setInvoices(response.data.data.data);
        setTotalInvoiceCount(response.data.data.total);
        setMessage(null)
      } else {
        console.error(ts(), "fetch invoice req status=", response.status, "body=", response.data.error)
        setMessage({kind: 'error', body: 'API ERROR ' + response.data.error})
      }
    } catch (e) {
      console.error(ts(),  "fetch invoice req error", e)
      setMessage({kind: 'error', body: 'API ERROR'})
    }
  }

  async function loadInvoiceRows(num) {
    try {
      if (!num) {
        console.error("nessun numero fattura specificato", num)
        setMessage({kind: 'error', body: `INTERNAL ERROR`})
        return
      }

      resetForm(true)

      let response = await getInvoiceRows(num)

      if (response.status === 200) {
        setInvoiceProducts(response.data.data)
        setMessage(null)
      } else {
        console.error(ts(), "fetch rows req status=", response.status, "body=", response.data.error)
        setMessage({kind: 'error', body: 'API ERROR ' + response.data.error})
      }
    } catch (e) {
      console.error(ts(),  "fetch rows req error", e)
      setMessage({kind: 'error', body: 'API ERROR'})
    }
  }

  useEffect(() => {
    async function fetchAll() {
      await loadInvoices('')
    }

    fetchAll()
  }, []);

  const handleClose = () => {
    setDiplayInvoiceSearchForm(true)
    setDiplayInvoiceList(true)
    setDiplayInvoiceProductList(false)
    setInvoiceProducts([])

    /// cancelliamo eventuali messaggi di errori api
    setMessage(null)

    // resettiamo la ricerca e ricarichiamo le invoices
    // nel caso la finestra venga riaperta in seguito
    lastsearch = ''
    setInvoiceSearchValue('')
    loadInvoices('')

    if ('sendMessage' in window) {
      window.sendMessage("CANCELLATO", intl.formatMessage({id: "CANCELLED"}));
    }
    onClose(selectedValue);
  };

  const handleInvoiceItemClick = async (value) => {
    if (parseInt(productPresent) === 0) {
      window.sendMessage(`${value.number}__!__${value.date}`, `${value.number}`);
      onClose(`${value.number}__!__${value.date}`);
      return true;
    }
    setInvoiceNumberSelected(value.number)
    setInvoiceDateSelected(value.date)
    await loadInvoiceRows(value.number)
  };

  function handleSubmit(event) {
    event.preventDefault()
    console.log(ts(), "prevent submit")
  }

  function handleKeyDown(event) {
    const key = event && event.key
    if (key === 'Enter') {
      event.preventDefault()
      console.log(ts(), "prevent submit (by keydown)")
    }
  }

  function setInvoiceSearchQuery(value) {
    lazyLoadInvoices(value)
    lastsearch = value
  }

  const backtoInvoiceHandle = async () => {
    setDiplayInvoiceSearchForm(true)
    setDiplayInvoiceList(true)
    setDiplayInvoiceProductList(false)
    setInvoiceSearchValue(lastsearch)
  }

  const onSearchChange = async (e) => {
    const val = e.target.value
    setInvoiceSearchValue(val)
    setInvoiceSearchQuery(val)
  }

  const onSaveAction = (selectedProducts) => {
    let separator = "__!__";
    let products = []

    if (selectedProducts.length === 0) {
      setMessageDiplayInvoiceProductList({
        kind: 'warning',
        body: translate('SELECT_AT_LEAST_ONE_PRODUCT')
      })
      return false;
    }

    for (let selectedProduct of selectedProducts) {
      selectedProducts['invoiceNumber'] = invoiceNumberSelected;
      selectedProducts['invoiceDate'] = invoiceDateSelected;

      if (selectedProduct.quantityReturned < 1) {
        alert(`One of selected products is not in invoice`);
        return false;
      }

      products.push({
        label: selectedProduct.sku,
        payload: `${invoiceNumberSelected}${separator}${invoiceDateSelected}${separator}${selectedProduct.quantity}${separator}${selectedProduct.quantityReturned}${separator}${selectedProduct.sku}${separator}${selectedProduct.productDescription1}${separator}${selectedProduct.productDescription2}`
      });
    }

    window.sendMessage(products[0].payload, products[0].label);
    products.shift()
    localStorage.setItem('productQueue', JSON.stringify(products));
    setDiplayInvoiceSearchForm(true);
    setDiplayInvoiceList(true);
    setDiplayInvoiceProductList(false);
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xl"
      modal={true}
      classes={{ root: classes.root }}
    >
      <DialogTitle id="simple-dialog-title">
        <button onClick={() => {
          handleClose()
        }}>Close
        </button>
      </DialogTitle>
      <DialogContent>
        <Box id="invoice-search">
          <InvoiceSearchForm
            display={displayInvoiceSearchForm}
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            onSearchChange={onSearchChange}
            title={firstCopyTab}
            subtitle={copyDisclaimer}
            invoiceSearchValue={invoiceSearchValue}
          />
        </Box>
        {message && <Box mt={2}>
          <Alert severity={message.kind}>{message.body} {message.spinner && <CircularProgress size="10px"/>}</Alert>
        </Box>}
        <Box id="invoices-list">
          <InvoiceList key={dateStart}
                       totalInvoiceCount={totalInvoiceCount}
                       invoices={invoices}
                       onClick={handleInvoiceItemClick}
                       displayList={displayInvoiceList}
          />
        </Box>
        <Box id="invoice-products-list">
          <ProductItemList
            invoiceNumber={invoiceNumberSelected}
            invoiceDate={invoiceDateSelected}
            invoiceProducts={invoiceProducts}
            displayList={displayInvoiceProductList}
            backToInvoice={backtoInvoiceHandle}
            message={messageInvoiceProductList}
            onSaveAction={onSaveAction}
            handleClose={handleClose}
            title={copySecondTab}
          />
        </Box>
      </DialogContent>

    </Dialog>
  );
}