import { LANGUAGES } from '../languages';

const IT = {
  [LANGUAGES.ITALIAN]: {
    "AT_LEAST_N_CHAR": "Inserire almeno {nchar} caratteri",
    "BACK_TO_HOME": "Torna alla homepage",
    "BACK_TO_INVOICE": "Torna al elenco fatture",
    "BACK_TO_LOGIN": "Torna alla login",
    "CANCEL": "Annulla",
    "CANCELLED": "CANCELLATO",
    "EMAIL_ADDRESS": "Indirizzo Email",
    "EMAIL_OR_PASSWORD_WRONG": "Email o password errati",
    "EMAIL_SENT": "Grazie. Se la tua e-mail \u00e8 inserita nel sistema riceverai una e-mail con le istruzioni per impostare una nuova password",
    "ERROR": "Errore",
    "FORGOT_PASSWORD": "Hai dimenticato la password",
    "HI": "Ciao",
    "INVOICE_DATE": "Data Fattura",
    "INVOICE_DESCRIPTION": "Descrizione Prodotto",
    "INVOICE_FOUND": "Fatture Trovate",
    "INVOICE_ITEM": "Codice Prodotto",
    "INVOICE_NUMBER": "Numero Fattura",
    "INVOICE_QUANTITY": "Quantit\u00e0",
    "INVOICE_QUANTITY_PURCHASED": "Quantit\u00e0 Acquistata",
    "INVOICE_QUANTITY_RETURNED": "Quantit\u00e0 da restituire",
    "LOADING": "Caricamento",
    "LOG_OUT": "Logout",
    "ONE_TAB_WARNING_MESSAGE": "Questo applicativo pu\u00f2 essere eseguito solo su una tab del browser",
    "PASSWORD": "Password",
    "PRODUCT_BRAND": "Brand",
    "PRODUCT_DESCRIPTION": "Descrizione Prodotto",
    "PRODUCT_FOUND": "Prodotti trovati",
    "PRODUCT_SKU": "Codice Prodotto",
    "RESET_PASSWORD_REQUEST": "Richiedi reset della password",
    "SAVE": "Salva",
    "SEARCH": "Cerca",
    "SEARCH_INVOICE": "Ricerca Fattura",
    "SEARCH_PRODUCT": "Ricerca Prodotto",
    "SELECT": "Seleziona",
    "SELECT_AT_LEAST_ONE_ITEM_TO_RETURN_FOR_PRODUCT": "Selezionare una quantit\u00e0 di almeno 1 pezzo per il prodotto",
    "SELECT_AT_LEAST_ONE_PRODUCT": "Selezionare almeno un prodotto",
    "SELECT_INVOICE": "Seleziona fattura",
    "SEND": "Invia",
    "SIGN_IN": "Accedi",
    "USER_IS_INVALID": "Questo account \u00e8 inattivo, ti preghiamo di contattare l\u2019assistenza tecnica",
    "VALUE_CANNOT_BE_EMPTY": "Il valore non pu\u00f2 essere vuoto",
    "VALUE_IS_REQUIRED": "Il valore \u00e8 obbligatorio",
    "VALUE_MUST_BE_AN_EMAIL": "il campo deve essere una email",
    "VALUE_MUST_BE_AN_OBJECT": "Il valore deve essere un oggetto",
    "VALUE_MUST_BE_A_STRING": "Il valore deve essere una stringa",
    "VALUE_TOO_LONG": "Il valore \u00e8 troppo lungo",
    "VALUE_TOO_SHORT": "Il valore \u00e8 troppo corto",
    "WELCOME_GUIDE": "Vi guider\u00e0 nell'aprire le richieste di garanzia, di riparazione e/o di reso e vi permetter\u00e0 di consultare in tempo reale lo stato delle stesse.",
    "WELCOME_MESSAGE": "Benvenuti nella piattaforma digitale dedicata al servizio post-vendita Shimano.",
    "WELCOME_TITLE": "After Sales Service Digital Platform",
    "WELCOME_VIRTUAL_ASSISTANCE": "L'assistente virtuale vi fornir\u00e0 un supporto facile e intuitivo nelle varie procedure relative all'assistenza Shimano."
  }
}

export default IT