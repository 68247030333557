import React /*, { useState }*/ from "react";
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import translate from '../i18n/translate';
import { FormattedMessage } from 'react-intl';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  searchKindSelector: {
    padding: '3px 4px'
  }
}));


export default function ProductSearchForm(props) {
  const classes = useStyles();
  //const [errors, setErrors] = useState([]);
  return (
    <div>
      {props.display === false ? ("") : (
        <div>
          <h1>{props.title}</h1>
          <form
            className={classes.form}
            noValidate
            onSubmit={props.onSubmit}
            onKeyDown={props.onKeyDown}
            onChange={props.invoiceSearchQuery}
          >
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={12} sm={4} md={4} className={classes.image}>
                 <Select className={classes.searchKindSelector}
                    native onChange={props.onSearchKindChange}
                  >
                    <FormattedMessage id='PRODUCT_SKU'>
                      {/* Non si può usare translate('PRODUCT_SKU') dentro ad option per cui bisogna ricorrere a questo workaround
                        * https://stackoverflow.com/questions/33441524/how-to-use-formattedmessage-inside-an-option-tag-in-react-0-14
                        *  */
                      (message) => <option value="sku">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id='PRODUCT_DESCRIPTION'>
                      {(message) => <option value="dsc">{message}</option>}
                    </FormattedMessage>
                  </Select>
              </Grid>
              <Grid item xs={12} sm={8} md={8} className={classes.image}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="search-invoice"
                  label={translate('SEARCH_PRODUCT')}
                  name="search"
                  autoComplete="search"
                  autoFocus
                  value={props.invoiceSearchValue}
                  onChange={(e) => props.onSearchChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.image}>

              </Grid>
            </Grid>
            {/*errors.length > 0 && <Box mt={2}>
              {errors.map((err) => (
                <Alert severity="error">{err.context.label} {translate(err.message)}</Alert>
              ))
              }
            </Box>*/}
            {props.message && <Box mt={2}>
              <Alert severity={props.message.kind}>{props.message.body} {props.message.spinner && <CircularProgress size="10px"/>}</Alert>
            </Box>}
          </form>
        </div>
      )}
    </div>
  );
}
    