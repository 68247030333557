import { LANGUAGES } from '../languages';

const ES = {
  [LANGUAGES.SPANISH]: {
    "AT_LEAST_N_CHAR": "Introducir al menos {nchar} caracteres",
    "BACK_TO_HOME": "Volver a la p\u00e1gina de inicio",
    "BACK_TO_INVOICE": "Volver a la lista de facturas",
    "BACK_TO_LOGIN": "Volver al inicio de sesi\u00f3n",
    "CANCEL": "Cancelar",
    "CANCELLED": "ANULADO",
    "EMAIL_ADDRESS": "Direcci\u00f3n de correo electr\u00f3nico",
    "EMAIL_OR_PASSWORD_WRONG": "El correo electr\u00f3nico o la contrase\u00f1a son incorrectos",
    "EMAIL_SENT": "Si el correo electr\u00f3nico est\u00e1 registrado en el formulario, recibir\u00e1 un correo electr\u00f3nico con instrucciones",
    "ERROR": "Error",
    "FORGOT_PASSWORD": "Olvid\u00e9 mi contrase\u00f1a",
    "HI": "Hola",
    "INVOICE_DATE": "Fecha de la factura",
    "INVOICE_DESCRIPTION": "Descripci\u00f3n del producto",
    "INVOICE_FOUND": "Facturas encontradas",
    "INVOICE_ITEM": "C\u00f3digo del art\u00edculo",
    "INVOICE_NUMBER": "N\u00famero de factura",
    "INVOICE_QUANTITY": "Cantidad",
    "INVOICE_QUANTITY_PURCHASED": "Cantidad facturada",
    "INVOICE_QUANTITY_RETURNED": "Cantidad a devolver",
    "LOADING": "Cargando",
    "LOG_OUT": "Cierre de sesi\u00f3n",
    "ONE_TAB_WARNING_MESSAGE": "Esta aplicaci\u00f3n se puede abrir en una sola pesta\u00f1a a la vez.",
    "PASSWORD": "Contrase\u00f1a",
    "PRODUCT_BRAND": "Marca",
    "PRODUCT_DESCRIPTION": "Descripci\u00f3n del producto",
    "PRODUCT_FOUND": "Productos encontrados",
    "PRODUCT_SKU": "C\u00f3digo del art\u00edculo",
    "RESET_PASSWORD_REQUEST": "Solicitar un restablecimiento de la contrase\u00f1a",
    "SAVE": "Guardar",
    "SEARCH": "Buscar en",
    "SEARCH_INVOICE": "B\u00fasqueda de facturas",
    "SEARCH_PRODUCT": "B\u00fasqueda de productos",
    "SELECT": "Selecciona",
    "SELECT_AT_LEAST_ONE_ITEM_TO_RETURN_FOR_PRODUCT": "Por favor, seleccione en la lista 1 unidad por producto seleccionado",
    "SELECT_AT_LEAST_ONE_PRODUCT": "Por favor, seleccione al menos un producto",
    "SELECT_INVOICE": "Seleccione la factura",
    "SEND": "Enviar",
    "SIGN_IN": "Iniciar sesi\u00f3n",
    "USER_IS_INVALID": "Esta cuenta est\u00e1 inactiva, por favor contacte con el Servicio de Atenci\u00f3n al Cliente de Shimano",
    "VALUE_CANNOT_BE_EMPTY": "El valor no puede estar vac\u00edo",
    "VALUE_IS_REQUIRED": "El valor es obligatorio",
    "VALUE_MUST_BE_AN_EMAIL": "El campo {field} El valor debe ser un correo electr\u00f3nico",
    "VALUE_MUST_BE_AN_OBJECT": "El valor debe ser un objeto",
    "VALUE_MUST_BE_A_STRING": "El valor debe ser una cadena",
    "VALUE_TOO_LONG": "El valor es demasiado largo",
    "VALUE_TOO_SHORT": "El valor es demasiado corto",
    "WELCOME_GUIDE": "Le guiar\u00e1 en la apertura de solicitudes de garant\u00eda, reparaci\u00f3n y/o devoluci\u00f3n y le permitir\u00e1 consultar el estado de sus tickets abiertos en tiempo real.",
    "WELCOME_MESSAGE": "Bienvenido a la plataforma digital del servicio posventa de Shimano.",
    "WELCOME_TITLE": "Plataforma Digital de Servicio Postventa",
    "WELCOME_VIRTUAL_ASSISTANCE": "El asistente virtual le proporcionar\u00e1 una ayuda f\u00e1cil e intuitiva sobre los procedimientos de servicio de Shimano."
  }
}

export default ES