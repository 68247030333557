import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LANGUAGES } from './languages';
import messages from './messages';

const Provider = ({ children, language = LANGUAGES.ENGLISH }) => (
  <IntlProvider
    locale={language}
    textComponent={Fragment}
    messages={messages[language]}
  >{children}</IntlProvider>
);

export default Provider;