import { LANGUAGES } from '../languages';

const EN = {
  [LANGUAGES.ENGLISH]: {
    "AT_LEAST_N_CHAR": "Type at least {nchar} characters",
    "BACK_TO_HOME": "Back to Homepage",
    "BACK_TO_INVOICE": "Back to Invoices list",
    "BACK_TO_LOGIN": "Back to login",
    "CANCEL": "Cancel",
    "CANCELLED": "CANCELLED",
    "EMAIL_ADDRESS": "Email Address",
    "EMAIL_OR_PASSWORD_WRONG": "Email or password wrong",
    "EMAIL_SENT": "If the email is registered in the plaform you'll receive an email with instructions",
    "ERROR": "Error",
    "FORGOT_PASSWORD": "Forgot password",
    "HI": "Hi",
    "INVOICE_DATE": "Invoice Date",
    "INVOICE_DESCRIPTION": "Product Description",
    "INVOICE_FOUND": "Found Invoices",
    "INVOICE_ITEM": "Item Code",
    "INVOICE_NUMBER": "Invoice Number",
    "INVOICE_QUANTITY": "Quantity",
    "INVOICE_QUANTITY_PURCHASED": "Invoiced Quantity",
    "INVOICE_QUANTITY_RETURNED": "Quantity to be returned",
    "LOADING": "Loading",
    "LOG_OUT": "Logout",
    "ONE_TAB_WARNING_MESSAGE": "This app can be open on a single tab at once.",
    "PASSWORD": "Password",
    "PRODUCT_BRAND": "Brand",
    "PRODUCT_DESCRIPTION": "Product Description",
    "PRODUCT_FOUND": "Found Products",
    "PRODUCT_SKU": "Item Code",
    "RESET_PASSWORD_REQUEST": "Request a password Reset",
    "SAVE": "Save",
    "SEARCH": "Search",
    "SEARCH_INVOICE": "Search for invoices",
    "SEARCH_PRODUCT": "Product Search",
    "SELECT": "Select",
    "SELECT_AT_LEAST_ONE_ITEM_TO_RETURN_FOR_PRODUCT": "Please select at list 1 unit per selected product",
    "SELECT_AT_LEAST_ONE_PRODUCT": "Please select at least one product",
    "SELECT_INVOICE": "Select Invoice",
    "SEND": "Send",
    "SIGN_IN": "Sign in",
    "USER_IS_INVALID": "This account is inactive, please contact Shimano Customer Service",
    "VALUE_CANNOT_BE_EMPTY": "Value cannot be empty",
    "VALUE_IS_REQUIRED": "Value is required",
    "VALUE_MUST_BE_AN_EMAIL": "the field {field} Value must be an email",
    "VALUE_MUST_BE_AN_OBJECT": "Value must be an object",
    "VALUE_MUST_BE_A_STRING": "Value must be a string",
    "VALUE_TOO_LONG": "Value is too long",
    "VALUE_TOO_SHORT": "Value is too short",
    "WELCOME_GUIDE": "It will guide you in opening warranty, repair and / or return requests and will allow you to consult the status of your open tickets in real time.",
    "WELCOME_MESSAGE": "Welcome to Shimano After Sales Service Digital Platform.",
    "WELCOME_TITLE": "After Sales Service Digital Platform",
    "WELCOME_VIRTUAL_ASSISTANCE": "The virtual assistant will provide you with easy and intuitive support on Shimano Service procedures."
  }
}

export default EN
