import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import { blue } from '@material-ui/core/colors';
import InvoiceSearchPopUp from './InvoiceSearchPopUp'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '2147483632 !important'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  }
}));


InvoiceSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function InvoiceSearch() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [firstCopyTab, setFirstCopyTab] = React.useState("");
  const [copyDisclaimer, setDisclaimerCopy] = React.useState("");
  const [copySecondTab, setCopySecondTab] = React.useState("");
  const [productPresent, setProductPresent] = React.useState(false);
  const [dateStart, setDateStart] = React.useState(null);
  const [dateEnd, setDateEnd] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    window.addEventListener('searchInvoice', (event) => {
      setFirstCopyTab(event.data.copyFirstTab);
      setDisclaimerCopy(event.data.copyDisclaimer);
      setCopySecondTab(event.data.copySecondTab);
      setProductPresent(event.data.productPresent);
      if ('dateStart' in event.data) {
        setDateStart(event.data.dateStart);
      }

      if ('dateEnd' in event.data) {
        setDateEnd(event.data.dateEnd);
      }
      setOpen(true);
    });
  });

  return (
    <div>
      <InvoiceSearchPopUp
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        firstCopyTab={firstCopyTab}
        copySecondTab={copySecondTab}
        copyDisclaimer={copyDisclaimer}
        productPresent={productPresent}
        dateStart={dateStart}
        dateSEnd={dateEnd}
      />
    </div>
  );
}
