import { LANGUAGES } from '../languages';

const PT = {
  [LANGUAGES.PORTUGUESE]: {
    "AT_LEAST_N_CHAR": "Digitar pelo menos {nchar} caracteres",
    "BACK_TO_HOME": "Voltar \u00e0 p\u00e1gina inicial",
    "BACK_TO_INVOICE": "Voltar \u00e0 lista de facturas",
    "BACK_TO_LOGIN": "Voltar para login",
    "CANCEL": "Cancelar",
    "CANCELLED": "CANCELADO",
    "EMAIL_ADDRESS": "O seu endere\u00e7o de correio electr\u00f3nico",
    "EMAIL_OR_PASSWORD_WRONG": "O e-mail ou palavra-passe est\u00e1 incorrecto",
    "EMAIL_SENT": "Se o e-mail estiver registado no formul\u00e1rio, receber\u00e1 um e-mail com instru\u00e7\u00f5es",
    "ERROR": "Erro",
    "FORGOT_PASSWORD": "Esqueci-me da minha palavra-chave",
    "HI": "Ol\u00e1",
    "INVOICE_DATE": "Data de factura\u00e7\u00e3o",
    "INVOICE_DESCRIPTION": "Descri\u00e7\u00e3o do produto",
    "INVOICE_FOUND": "Facturas encontradas",
    "INVOICE_ITEM": "C\u00f3digo do artigo",
    "INVOICE_NUMBER": "N\u00famero da factura",
    "INVOICE_QUANTITY": "Quantidade",
    "INVOICE_QUANTITY_PURCHASED": "Quantidade facturada",
    "INVOICE_QUANTITY_RETURNED": "Quantidade a ser devolvida",
    "LOADING": "Carregamento",
    "LOG_OUT": "Sair",
    "ONE_TAB_WARNING_MESSAGE": "Esta aplica\u00e7\u00e3o pode ser aberta em apenas um separador de cada vez.",
    "PASSWORD": "Senha",
    "PRODUCT_BRAND": "Marca",
    "PRODUCT_DESCRIPTION": "Descri\u00e7\u00e3o do produto",
    "PRODUCT_FOUND": "Produtos encontrados",
    "PRODUCT_SKU": "C\u00f3digo do produto",
    "RESET_PASSWORD_REQUEST": "Pedir a redefini\u00e7\u00e3o de uma palavra-passe",
    "SAVE": "Guardar",
    "SEARCH": "Pesquisar em",
    "SEARCH_INVOICE": "Pesquisa de facturas",
    "SEARCH_PRODUCT": "Pesquisa de produtos",
    "SELECT": "Seleccione",
    "SELECT_AT_LEAST_ONE_ITEM_TO_RETURN_FOR_PRODUCT": "Por favor seleccione 1 unidade por produto seleccionado da lista.",
    "SELECT_AT_LEAST_ONE_PRODUCT": "Por favor seleccione pelo menos um produto",
    "SELECT_INVOICE": "Seleccione a factura",
    "SEND": "Enviar para",
    "SIGN_IN": "Iniciar Sess\u00e3o",
    "USER_IS_INVALID": "Esta conta est\u00e1 inactiva, por favor contacte o Servi\u00e7o de Apoio ao Cliente Shimano.",
    "VALUE_CANNOT_BE_EMPTY": "O valor n\u00e3o pode estar vazio",
    "VALUE_IS_REQUIRED": "O valor \u00e9 necess\u00e1rio",
    "VALUE_MUST_BE_AN_EMAIL": "Campo {campo} O valor deve ser um endere\u00e7o de e-mail",
    "VALUE_MUST_BE_AN_OBJECT": "O valor deve ser um objecto",
    "VALUE_MUST_BE_A_STRING": "O valor deve ser uma cadeia",
    "VALUE_TOO_LONG": "O valor \u00e9 demasiado longo",
    "VALUE_TOO_SHORT": "O valor \u00e9 demasiado baixo",
    "WELCOME_GUIDE": "Orient\u00e1-lo-\u00e1 na garantia de abertura, repara\u00e7\u00e3o e/ou pedidos de devolu\u00e7\u00e3o e permitir-lhe-\u00e1 verificar o estado dos seus bilhetes abertos em tempo real.",
    "WELCOME_MESSAGE": "Bem-vindo \u00e0 plataforma de servi\u00e7o p\u00f3s-venda digital da Shimano.",
    "WELCOME_TITLE": "Plataforma de Servi\u00e7o P\u00f3s-Venda Digital",
    "WELCOME_VIRTUAL_ASSISTANCE": "O assistente virtual ir\u00e1 fornecer-lhe uma ajuda f\u00e1cil e intuitiva sobre os procedimentos de servi\u00e7o Shimano."
  }
}

export default PT